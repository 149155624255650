/* eslint-disable no-undef */
<template>
  <div id="Branches">
    <Header
      :title="$t('Branches.header.title')"
      :description="$t('Branches.header.description')"
    />
    <div class="relative h-screen py-16">
      <div id="map" class="h-full"></div>
    </div>
    <div v-if="loading" class="flex items-center justify-center my-12">
      <i class="bx bx-loader-circle bx-spin text-5xl text-primary"></i>
    </div>
    <div v-else class="container mx-auto px-5 lg:px-8">
      <div class="w-3/4 md:w-1/2 lg:w-1/3 mx-auto flex items-center">
        <input
          type="search"
          name="search"
          id="search"
          v-model="search"
          :placeholder="$t('Branches.placeholder')"
          class="w-full py-3 border border-searchBorder px-5"
        />
        <button class="bg-btn border border-btn px-8 py-3 text-white">
          ابحث
        </button>
      </div>
      <table v-if="Branches.length > 0" class="w-full mx-auto my-8">
        <thead class="bg-readmoreBG text-newsDescription">
          <tr class="">
            <th
              class="text-center py-5 text-sm md:text-md"
              v-for="(item, index) in th"
              :key="index"
            >
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="
              text-center
              border border-readmoreBG
              text-footerBg text-md
              md:text-xl
              font-medium
            "
            v-for="branch in Branches"
            :key="branch.id"
          >
            <td
              class="
                py-5
                relative
                flex
                items-center
                justify-center
                text-xs
                md:text-sm
              "
            >
              <img
                alt=""
                loading="lazy"
                src="../assets/map-pin.svg"
                class="absolute cursor-pointer object-cover"
                @click="flyTo(branch)"
              />
              <span
                @click="flyTo(branch)"
                class="z-20 p-1 text-white cursor-pointer"
                >{{ branch.id }}</span
              >
            </td>
            <td class="py-5">
              {{ $i18n.locale == "ar" ? branch.name_ar : branch.name_en }}
            </td>
            <td class="py-5">
              {{
                $i18n.locale == "ar"
                  ? branch.users.find((user) => user.status == "مسؤول")
                      .name_ar || "-"
                  : branch.users.find((user) => user.status == "مسؤول")
                      .name_en || "-"
              }}
            </td>
            <td class="py-5">
              {{
                branch.users.find((user) => user.status == "مسؤول").phone_number
              }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="my-8">
        <h1 class="text-3xl font-bold tracking-wide text-black text-center">
          {{ $t("Branches.noResuit") }}
        </h1>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import mapboxgl from "mapbox-gl";
import Header from "@/components/layout/Header";

export default {
  name: "Branches",
  data() {
    return {
      URL: process.env.VUE_APP_URL,
      loading: true,
      th: [
        "#",
        this.$t("Branches.BranchName"),
        this.$t("Branches.managerName"),
        this.$t("Branches.phoneNumber"),
        "",
      ],
      search: "",
    };
  },
  components: {
    Header,
  },
  async mounted() {
    const map = new mapboxgl.Map({
      container: "map", // container ID
      style: "mapbox://styles/mapbox/streets-v11", // style URL
      center: [16.180161, 30.885353], // starting position [lng, lat]
      zoom: 5, // starting zoom
    });
    map.scrollZoom.disable();

    map.addControl(new mapboxgl.NavigationControl());

    Vue.prototype.$map = map;

    await this.getBranches();

    this.Branches.forEach((branch) => {
      var el = document.createElement("div");
      el.className = "marker";
      el.innerHTML = branch.id;
      new mapboxgl.Marker(el)
        .setLngLat([branch.long, branch.lat])
        .setPopup(
          new mapboxgl.Popup().setHTML(
            `<h1>${
              this.$i18n.locale == "ar" ? branch.name_ar : branch.name_en
            }</h1>`
          )
        )
        .addTo(map);
    });
  },
  methods: {
    async getBranches() {
      await this.$store.dispatch("getBranches");
      this.loading = false;
    },
    flyTo(branch) {
      document.getElementById("map").scrollIntoView({
        behavior: "smooth",
      });
      this.$map.flyTo({
        center: [branch.long, branch.lat],
        essential: true,
      });
    },
  },
  computed: {
    Branches() {
      return this.$store.getters.getBranches.filter((branch) =>
        this.$i18n.locale == "ar"
          ? branch.name_ar.match(this.search)
          : branch.name_en.match(this.search)
      );
    },
  },
};
</script>
